import { useState , useContext} from "react";
import $ from "jquery";
import { useNavigate} from 'react-router-dom'; 
import planContext from "../context/plan/planContext";
import SelectedPlan from "./plans/SelectedPlan";

function Order() { 
    const plan = useContext(planContext);
    
    const [addressLookupModel ,setAddressLookupModel] = useState(null);

    let navigate = useNavigate(); 
    const routeChange = () =>{ 
        let path = `/thankyou`; 
        navigate(path);
    }
    
    const handlePostCode = (e) => {
        
        e.preventDefault();
        const loader = document.getElementById('loading-gif');
        loader.style.display = 'block';

        var postcode= document.getElementById('postcode').value 
        const params = {
            method: 'POST',
            headers: {                
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
            }
        };
        fetch('https://func-boshhh-addresslookup-prod-001.azurewebsites.net/api/request?postcode='+postcode,params)
        .then(res=>res.json())
        .then(data=>{
            setAddressLookupModel(data);
            loader.style.display = 'none';
        }) 
    }
    

	const handleSumbit = (e) => {
		e.preventDefault();
		const form = $(e.target);
		$.ajax({
			type: "POST",
			url: form.attr("action"),
			data: form.serialize(),
			success(data) {
                routeChange();
			},
		});
        
	}; 
    
    
	return (
		<div className="order pt-5"> 
         <form
                    action="https://dev.creditize.co.uk/boshh-business/handler.php"
                    method="post"
                    onSubmit={(event) => handleSumbit(event)} >
            <div className="col-md-8 col-sm-8 col-lg-8 mb-4">
                    <h2 className='m-'>Your details</h2>
                    <input className="form-control" type="hidden" id="numOfSim" name="numOfSim" value={plan.state.title}/>
                    <input className="form-control" type="hidden" id="packageName" name="packageName" value={plan.simNum}/>
                    <div className="radio-button d-flex my-2">
                        <div>
                            <input type="radio" id="Mr" name="title" value={'Mr'} />
                            <label for="Mr">Mr</label>
                        </div>   
                        <div>
                            <input type="radio" id="Mrs" name="title" value={'Mrs'} />
                            <label for="Mrs">Mrs</label>
                        </div>   
                        <div>
                            <input type="radio" id="Miss" name="title" value={'Miss'} />
                            <label for="Miss">Miss</label>
                        </div>   
                        <div>
                            <input type="radio" id="Ms" name="title" value={'Ms'} />
                            <label for="Ms">Ms</label>
                        </div>
                    </div>
                    <div>
                        <label>First Name</label>
                        <input className="form-control" autoFocus type="text" id="firstName" name="firstName" required/>
                    </div>
                    <div>
                        <label>Last Name</label>
                        <input className="form-control" type="text" id="lastName" name="lastName"	 required/>
                    </div>
                    <div>
                        <label>Date Of Birth</label>
                        <input className="form-control" type="date" id="dob"  name="dob" required/>
                    </div>
                    <div>
                        <label>Phone Number</label>
                        <input className="form-control" type="number" id="phoneNumber"  name="phoneNumber"  	 required/>
                    </div>
                    <div>
                        <label>Email</label> 
                        <input className="form-control" type="email" id="email" name="email"  required	/>
                    </div>
                    <h2 className='my-4'>Company Details </h2>
                    <div>
                        <label>Company Name</label>
                        <input className="form-control" type="text" id="companyName"  name="companyName"  	 required/>
                    </div>
                    <div>
                        <label>VAT Number</label>
                        <input className="form-control" type="number" id="vatNumber" name="vatNumber"	required/>
                    </div>
                    <h2 className='my-4'>Company Address</h2>
                    <div className="find-postcodejustify-content-between align-items-center row" >
                        <div className="col-sm-6 col-lg-6 col-md-6">
                            <label>Postcode</label>
                            <input className="form-control" type="text" id="postcode"   name="postcode"  	required/>
                        </div>
                        <div className="col-sm-6 col-lg-6 col-md-6">
                            <button className="btn w-100 bg-theme text-light" onClick={(e) => handlePostCode(e)}>Find Address</button>
                        </div>
                    </div>
                    <img id="loading-gif" src="https://boshhh.com/assets/images/boshh-dark-spinner.gif" alt="loading-gif" width={80}/>
                    <label>Select Address</label>
                    
                    <select className="" name="address">
                        {
                             addressLookupModel!=null &&
                                addressLookupModel.thoroughfares.map((model)=>
                                <option value={model.name}>{model.name}</option>
                                ) 
                        }  
                    </select>

                   
                       
                    <div>
                        <label>City</label>
                        {/* <input className="form-control" type="text" id="city"  value={addressLookupModel!=null && addressLookupModel.city}  name="city"	readOnly /> */}
                        <input className="form-control" type="text" id="city"  value={
                            (() => {
                                if(addressLookupModel !=null){
                                    return addressLookupModel.town
                                }
                                return null;
                            })()
                        }
                            name="city"	readOnly />
                    </div>
                    <div>
                        <label>County</label>
                        <input className="form-control" type="text" id="county" value={
                               (() => {
                                if(addressLookupModel !=null){
                                    return addressLookupModel.county
                                }
                                return null;
                            })()
                        }   name="county" readOnly  required/>
                    </div>
                    
                    <button type="submit" className="btn">Submit</button>
			</div>
            <div className="col-md-4 col-sm-4  col-lg-4">
                <h2 className='ps-3 mb-4'>Order Summary</h2>
                <SelectedPlan navigate={navigate} colWidth={12} plan={plan.state} />
            </div>
        </form> 
		</div>
	);
}

export default Order;
